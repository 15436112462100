/* eslint-disable @next/next/no-img-element */
import { Breakpoints } from '@helpers/constants';
import { clsx } from '@wonderful/wwc/dist/src/helpers/clsx';
import styles from './BuilderImage.module.scss';
import type { BuilderImageProps } from './BuilderImage.types';

export default function BuilderImage(props: BuilderImageProps) {
  const {
    mobileWidth,
    tabletWidth,
    desktopWidth,
    imageSrc,
    imageSrcMobile,
    classes,
    alt,
    disableLazyLoad,
    ...rest
  } = props;

  const getQueryParams = (format: string, width?: string) => {
    return `?format=${format}${!!width ? '&width=' + width : ''}`;
  };

  if (imageSrc) {
    return (
      <picture
        className={clsx(
          styles.BuilderImage,
          imageSrcMobile ? styles.mobileImage : '',
          classes
        )}
      >
        {/* desktop */}
        {desktopWidth ? (
          <>
            <source
              media={`(min-width: ${Breakpoints.lg}px)`}
              srcSet={`${imageSrc}${getQueryParams('webp', desktopWidth)}`}
              type="image/webp"
            />
            <source
              media={`(min-width: ${Breakpoints.lg}px)`}
              srcSet={`${imageSrc}${getQueryParams('jpeg', desktopWidth)}`}
              type="image/jpeg"
            />
          </>
        ) : null}

        {/* tablet */}
        {tabletWidth ? (
          <>
            <source
              media={`(min-width: ${Breakpoints.md}px)`}
              srcSet={`${imageSrc}${getQueryParams('webp', tabletWidth)}`}
              type="image/webp"
            />
            <source
              media={`(min-width: ${Breakpoints.md}px)`}
              srcSet={`${imageSrc}${getQueryParams('jpeg', tabletWidth)}`}
              type="image/jpeg"
            />
          </>
        ) : null}

        {/* mobile */}
        {mobileWidth ? (
          <>
            <source
              srcSet={`${
                imageSrcMobile ? imageSrcMobile : imageSrc
              }${getQueryParams('webp', mobileWidth)}`}
              type="image/webp"
            />
            <source
              srcSet={`${
                imageSrcMobile ? imageSrcMobile : imageSrc
              }${getQueryParams('jpeg', mobileWidth)}`}
              type="image/jpeg"
            />
          </>
        ) : null}

        <img
          src={`${imageSrcMobile ? imageSrcMobile : imageSrc}${getQueryParams(
            'jpeg',
            mobileWidth
          )}`}
          alt={alt || ''}
          loading={disableLazyLoad ? 'eager' : 'lazy'}
          {...rest}
        />
      </picture>
    );
  } else {
    return null;
  }
}
